import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core'
import { Avatar } from '@material-ui/core';

import { ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    marginBot24: {
        marginBottom: theme.spacing(3),
    },
    textCenter: {
        textAlign: "center"
    },
    fontWeight7: {
        fontWeight: 700,
    },
    reviewGrid: {
        border: 0,
        [theme.breakpoints.down('xs')]: {
            borderRight: '1px solid #cfd8dc',
            '&:nth-child(2n)': {
                borderRight: 0,
            },
            '&:nth-child(-n+4)': {
                borderBottom: '1px solid #cfd8dc',
            }
        },
        [theme.breakpoints.up('md')]: {
            borderRight: '1px solid #cfd8dc',
            '&:nth-child(2n)': {
                borderRight: '1px solid #cfd8dc',
            },
            '&:nth-child(-n+4)': {
                borderBottom: 0,
            },
            '&:nth-child(3n)': {
                borderRight: 0,
            },
            '&:nth-child(-n+3)': {
                borderBottom: '1px solid #cfd8dc',
            },
        }
    }
}));

function Review() {
    const classes = useStyles()
    return (
        <Grid container className={classes.reviewGrid} item xs={12} sm={6} md={4}>
            <Grid container align="left">
                <Grid item xs={12}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar alt="aa" className="review-avatar" src="https://picsum.photos/350/250" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant="h6" component="span">Veronica Adams</Typography>}
                            secondary={<Typography variant="body1" component="p" color="textSecondary">Growth Marketer, Crealytics</Typography>} />
                    </ListItem>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' component='h6'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quas consectetur beatae ullam explicabo! Obcaecati, voluptatibus.</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

function ReviewList() {
    const classes = useStyles()
    return (
        <div>
            <Grid container className={classes.textCenter}>
                <Grid item xs={12}>
                    <Typography variant="h4" color="textPrimary" className={classes.fontWeight7}> Our customers are our biggest fans.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.marginBot24}>
                    <Typography variant="h6" color="textSecondary" className={classes.fontWeight7}>We dont' like to brag, but we don't mind letting our customers do it for us. Here are a few nice things folks have said about our themes over the years.</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Review />
                <Review />
                <Review />
                <Review />
                <Review />
                <Review />
            </Grid>
        </div>
    )
}
export default ReviewList