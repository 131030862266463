import React from 'react'

import theme from '../utils/theme'
import Layout from '../components/layout'
import SwiperSlide from '../components/swiper'
import WorkProcess from '../components/home/WorkProcess'
import TripCardList from "../components/home/TripCardList"
import ReviewList from "../components/home/ReviewList"

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Button, Grid, Typography, Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    introWrapper: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
        maxWidth: '1240px',
        margin: '0 auto',
    },
    introMsg: {
        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px',
        },
        padding: '96px 40px'
    },
    introSlider: {
        [theme.breakpoints.down('sm')]: {
            height: '300px',
            width: '100%'
        },
        width: '50vw',
        height: '100%'
    },
    componentWrapper: {
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(8)}px  64px`,
        },
        maxWidth: '1240px',
        padding: `${theme.spacing(12)}px 40px`
    },
}))

const Home = () => {
    const classes = useStyles()
    return (

        <ThemeProvider theme={theme}>
            <Layout>
                <Grid container className={classes.introWrapper} direction="row">
                    <Grid container item xs={12} md={6} className={classes.introMsg} spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">
                                Finding traveling partner made <Typography variant="h3" component="span" color="primary">easy</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6" color="textSecondary">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque, a!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" href="#contained-buttons">
                                CREATE TRIP
                            </Button>
                            {/* FIX Button inlin margin */}
                            <Button variant="outlined" color="primary" href="#outlined-buttons" style={{ marginLeft: '10px' }}>
                                BROWSE TRIP
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div className={classes.introSlider}>
                            <SwiperSlide clipStyle="clip-right" />
                        </div>
                    </Grid>
                </Grid>

                <Container className={classes.componentWrapper}>
                    <WorkProcess />
                </Container>

                <Container className={classes.componentWrapper}>
                    <TripCardList />
                </Container>

                <Container className={classes.componentWrapper}>
                    <ReviewList />
                </Container>
            </Layout>
        </ThemeProvider >
    )
}
export default Home