import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core'
import { Avatar } from '@material-ui/core';

import SwiperSlide from "../swiper"


const useStyles = makeStyles({
    listCardMedia: {
        height: '300px',
    },
    fontWeight7: {
        fontWeight: 700
    },
    fontSmallBold: {
        fontWeight: 'bold',
    },
    learnMoreLink: {
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
    },
    followerAvatar: {
        border: '3px solid #fff',
        marginLeft: '-16px',
        '&:first-child': {
            marginLeft: 0,
        },
        '& img': {
            marginBottom: 0,
        }
    }
});

//Individual Trip Card
function TripCard() {
    const classes = useStyles()
    return (
        <Card>
            <CardMedia
                alt="Trip Image"
                title="Trip Image"
                className={classes.listCardMedia}
            >
                <SwiperSlide clipStyle="clip-bottom-round" />
            </CardMedia>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={classes.fontWeight7} variant="h6" component="h6" align="left">
                            Naples, Italy
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary" component="p" align="left">
                            Lorem ipsum dolor sit
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} justify='space-between'>
                        <Grid container item xs={6} wrap='nowrap'>
                            <Avatar alt="aa" className={classes.followerAvatar} src="https://picsum.photos/350/250" />
                            <Avatar alt="bb" className={classes.followerAvatar} src="https://picsum.photos/350/250" />
                            <Avatar alt="cc" className={classes.followerAvatar} src="https://picsum.photos/350/250" />
                        </Grid>
                        <Grid container item xs={6} justify="flex-end" alignItems="center">
                            <Typography className={classes.fontSmallBold} variant="body1" component="span"> 5.0</Typography>
                            <Typography variant="body2" component="span" color="textSecondary">(28 reviews)</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justify="flex-end" xs={12}>
                        <a className={classes.learnMoreLink} href="#">
                            <Typography className={classes.fontSmallBold} variant="subtitle1" color="primary" component="span">
                                Learn More
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}


//Generate List of TripCard and export
function TripCardList() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <TripCard />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TripCard />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TripCard />
            </Grid>
        </Grid>
    )
}

export default TripCardList