import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core'

import CreateIcon from '@material-ui/icons/Create';

// import './styles/WorkProcess.scss'

const useStyles = makeStyles((theme) => ({
    textCenter: {
        textAlign: "center"
    },
    marginBot24: {
        marginBottom: theme.spacing(4),
    },

}));

function ProcessGrid() {
    return (
        <Grid container item xs={12} md={4}>
            <Grid item xs={12}><CreateIcon /></Grid>
            <Grid item xs={12}><Typography variant="h6" >Create</Typography></Grid>
            <Grid item xs={12}><Typography variant="body2" color="textSecondary" component="p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, expedita.</Typography></Grid>
        </Grid>
    )
}

function WorkProcess() {
    const classes = useStyles()
    return (
        <div>
            <Grid container className={classes.textCenter}>
                <Grid item xs={12} className={classes.marginBot24}>
                    <Typography variant="overline" color="primary" component="p">PROCESS WE DO</Typography>
                    <Typography variant="h4" color="textPrimary" component="h4" className={classes.fontWeight7}>How it works</Typography>
                </Grid>
                <Grid container className={classes.marginBot24} spacing={4}>
                    <ProcessGrid />
                    <ProcessGrid />
                    <ProcessGrid />
                </Grid>
                <Grid item xs={12} className={classes.marginBot24}>
                    <Button variant="contained" color="primary" href="#contained-buttons">
                        GET STARTED
                    </Button>
                </Grid>
            </Grid>
        </div>
    )

}

export default WorkProcess